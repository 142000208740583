import { useState, useEffect, useCallback } from 'react';
import Amplify, { DataStore } from "aws-amplify";
import { ContactForm } from '../../models';

//Use next two lines only if syncing with the cloud
import awsconfig from "../../aws-exports";
Amplify.configure(awsconfig);

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState()
  const [state, setState] = useState({ value: "", expired: "false" })

  const handleSubmit = (e, { values, action } = {}) => {
    e.preventDefault()
    if (state.value) {
      setTarget(e.target)
      setSubmitting(true)
    }
  }

  const handleChange = value => {

    setState({ value });
    // if value is null recaptcha expired
    if (value === null) setState({ expired: "true" });

  }

  const sendValues = useCallback(() => {

    const form = new FormData(target)

   DataStore.save(
        new ContactForm({
        "name": form.get('name'),
        "email": form.get('email'),
        "subject": form.get('subject'),
        "message": form.get('message')
      })
    ).then(() => {
        target.reset()
        setSuccess(true)
      })
      .catch(error => {
        setSuccess(false)
      })
      .finally(() => {
        setSubmitting(false)
      })
  },[target])

  useEffect(() => {
    if (submitting) {
      sendValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, sendValues])

  const reset = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
  }, [])

  return {
    handleChange,
    handleSubmit,
    submitting,
    success,
    reset
  }
}

export default useForm
