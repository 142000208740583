import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact Free Web Stack' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Get in touch with us"
          subheader="We don't just share our knowledge with passion. We also help clients solve their problems and provide them the tools they need to succeed in today's competitive digital world. Don't hesitate to contact us if you have any questions about Magento, JAMStack, PWAs or Web Performance - we're happy to answer your queries!"
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
